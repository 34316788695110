<template>
    <div id="wrapper">
        <!-- top -->
        <div class="topbar clearfix">
            <div class="topbar-left fl">
                <div class="text-center">
                    <a href="/Myclass" class="logo">
                        <img src="./../../assets/img/logo.png" alt="" class="img">
                    </a>
                </div>
            </div>
            <div class="text—name fl">
               <span> · </span>学员端
            </div>
            <div class="topbar-left info-cont fr">
                <div class="u-avatar fr" @mouseover="mouseover()" @mouseout="mouseout()">
                    <div class="fl member">
                        <img class="avatar fl" :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="">
                    </div>
                    <p class="name fl">{{this.$store.state.user_name===null||this.$store.state.user_name===''||this.$store.state.user_name==='null'?'':this.$store.state.user_name}}</p>
                    <ul class="dropdown-menu" v-show="login_acc==true">
                        <li @click="count()">账号设置</li>
                        <li @click="exit()">安全退出</li>
                    </ul>
                </div>
                <!-- <div class="menu_one fr">
                    <el-tooltip class="item" effect="dark" content="项目通知" placement="bottom">
                        
                        <el-popover
                            placement="bottom-end"
                            width="400"
                            trigger="click"
                            popper-class="popoverDiv"
                        >
                            <div>123</div>
                            <i class="iconfont icon-tongzhi"  slot="reference"></i>
                        </el-popover>
                    </el-tooltip>
                </div> -->
                <div class="menu_one fr" v-show="database">
                    <el-tooltip class="item" effect="dark" content="资料库" placement="bottom">
                        <i class="iconfont icon-folder" @click="drawers()"></i>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <!-- center -->
        <div class="center" v-if="this.$route.name=='StudentSetting'">
            <router-view></router-view>
        </div>
        <div class="center clearfix" v-else>
            <div class="center_left fl">
                <el-menu
                    :default-active="activePath"
                    :unique-opened='true'
                    class="el-menu-vertical-demo"
                    background-color="#fff"
                    text-color="#272727"
                    active-text-color="#ffd04b">
                        <router-link to="Myclass">
                            <el-menu-item index="Myclass">
                                <i class="el-icon-date" style="margin-right:10px;font-size:18px"></i>
                                <span slot="title">我的课堂</span>
                            </el-menu-item>
                        </router-link>
                </el-menu>
                <div class="Materia">
                    <img src="./../../assets/img/logosck.png" alt="" @click="jumps()">
                </div>
            </div>
            <div class="center_right fl">
                <router-view></router-view>
            </div>
        </div>
        <!-- footer -->
        <footer class="footer text-right">
            <span>© 2022. 创项课_v1.2.0  All rights reserved.</span>
        </footer>
        <!-- 资源库抽屉 -->
        <div class="drawerBox">
            <el-drawer
                size="450px"
                title="资料库"
                :visible.sync="drawer"
                :with-header="true">
                <div class="mu_list">
                    <ul>
                        <li v-for="(item,index) in drawList" :key="index" v-show="drawList.length!=0">
                            <div class="bm-drake-box">
                                <div class="mu-item">
                                    <div class="mu-item-left">
                                        <div class="mu-avatar">
                                            <div class="mu-avatar-inner">
                                                <img src="./../../assets/img/ppt.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mu-item_item clearfix">
                                        <div class="item_left fl">
                                            <span>{{item.name}}</span>
                                        </div>
                                        <div class="item_right fr">
                                            <i class="iconfont icon-xiazai" @click="download(item)"></i>
                                        </div>
                                    </div>
                                    <hr class="mu-divider">
                                </div>
                            </div>
                        </li>
                        <div class="default-wrapper" v-show="drawList.length==0">
                            <img src="./../../assets/img/bookmark.svg" alt="">
                            <p><span>暂无数据</span></p>
                        </div>
                    </ul>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            login_acc:false,
            activePath:'',
            database:false,
            drawer:false,
            drawList:[],
        }
    },
    methods: {
        drawers(){
            this.axios.coursedoc({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.drawList = res.data.data
                    this.drawer = true
                }
            }).catch(err=>{

            })
        },
        // 下载
        download(item){
            window.open(item.url, "_blank");
        },  
        mouseover(){
            this.login_acc = true
        },
        mouseout(){
            this.login_acc = false
        },
        getPath(){
            let currentPath = this.$route
            this.activePath = currentPath.name
            if(this.activePath =='Projectcreation'){
                this.activePath = 'Myclass'
                this.database = true
            }else{
                this.database=false
            }
        },
        exit(){
            this.$router.push('/login')
            localStorage.clear()
        },
        count(){
            this.$router.push({name:'StudentSetting'})
        },
        jumps(){
            var args = this.$store.state.token
            window.open('https://www.ibeisai.com?'+args)
        },
    },
    watch:{
        $route(){
            this.getPath()
        }
    },
    mounted() {
        this.getPath()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/index.css';
</style>
<style>
.center_left .el-menu-item i{
    color: #272727;
}
.center_left .el-menu{
    border: none;
}
.center_left .el-menu-item, .el-submenu__title{
    height: 45px;
    line-height: 45px;
    margin: 5px 0;
    padding-left: 24px!important;
}
.center_left .is-active{
    background: #EBEFF2!important;
    border-left: 4px solid #3E67D9 !important;
    color: #3E67D9 !important;
}
.center_left .is-active i{
    color: #3E67D9 !important;
}
.center_left .el-menu-item.is-active{
    padding-left: 20px!important;
}
/* 资源库抽屉 */
.drawerBox .el-drawer__header{
    padding: 0 8px;
    height: 44px;
    line-height: 44px;
    background: #f1f6fe;
    color: #0e1726;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
</style>